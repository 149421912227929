.scenariosListContainer {
    background-color: white;
    padding: 15px;
}

.modalOverlay {
    position: fixed;
    top: 0px;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(255, 255, 255, 0.3);
    box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
    backdrop-filter: blur(2px);
    -webkit-backdrop-filter: blur(2px);
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 100;
}

.modalContent {

    position: absolute;
    top: 40%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: white;
    padding: 20px;
    border-radius: 5px;
    box-shadow: -3px -3px 5px rgba(255, 255, 255, 0.1), 3px 3px 5px rgba(94, 104, 121, 0.2);

}

.closeIcon {
    margin-left: 250px;
}

.scenariosContainer {
    list-style-type: none;
    padding: 0px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 270px;
    max-height: 10vh;
    overflow-y: auto;
}

.scenarioRow {
    background-color: #f6f7f899;
    border-left: 5px solid #f6f7f899;
    border-radius: 2px;
    list-style-type: none;
    padding: 0px;
    margin-bottom: 12px;
    display: flex;
    flex-direction: row;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    /* border: none; */
    color: #7a7a7a;
    padding: 10px 18px;
    text-align: left;
    font-size: 14px;
    cursor: pointer;
}

.selectedScenario {
    background-color: #f6f7f8;
    border-left: 5px solid #024761;
}

.scenarioContent {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    flex-grow: 1;
    max-width: 150px;
    margin-right: 10px;
}

.scenarioIcon {
    height: 18px !important;
    width: 18px !important;
}

.addScenario {
    cursor: pointer;
    padding-top: 10px;
}