.modalOverlay {
  position: fixed;
  top: 0px;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.3);
  box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
  backdrop-filter: blur(2px);
  -webkit-backdrop-filter: blur(2px);
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  /* z-index: 100; */
  z-index: 99999;


}

.modalContent {

  position: absolute;
  width: 400px;
  top: 40%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  padding: 20px;
  border-radius: 5px;
  box-shadow: -3px -3px 5px rgba(255, 255, 255, 0.1), 3px 3px 5px rgba(94, 104, 121, 0.2);

}



input[type="text"] {
  border: 1px solid #7a7a7a4a;
  border-radius: 3px;
  padding: .5rem 1rem;
  font-size: 16px;
  font-family: "Roboto", sans-serif;
  color: #7a7a7a;
  margin-bottom: 2em;
}

  textarea {
    /* border: 1px solid #7a7a7a4a;
    border-radius: 3px;
    padding: .5rem 1rem;
    font-family: "Rbboto", sans-serif;
    font-size: 16px;
    color: #7a7a7a;
    transition: all .3s;
    margin-bottom: 2em;
    white-space: pre-wrap;
    width: 320px; */
  }
textarea {
  border: 1px solid #7a7a7a4a;
  border-radius: 3px;
  padding: .5rem 1rem;
  font-family: "Rbboto", sans-serif;
  font-size: 16px;
  color: #7a7a7a;
  transition: all .3s;
  margin-bottom: 2em;
  white-space: pre-wrap;
  /* width: 320px; */
}

.closeIcon {
  cursor: pointer;
}