.container {
  /* background: #f5f6fa !important;
  padding: 30px !important;
  text-align: left; */
}

.popup-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: none;
}

.popup-overlay.active {
  display: block;
}


label {
  font-size: 16px !important;
}


.audienceItem {
  display: inline-flex;
  align-items: center;
  margin-right: 8px;
  padding: 4px 8px;
  background-color: #f0f0f0;
  border-radius: 4px;
}

.audienceItem .MuiSvgIcon-root {
  cursor: pointer;
  margin-left: 4px;
}