
.roadBloxksListContainer {
  padding: 15px;
}


.roadBlocksContainer {
  list-style-type: none;
  padding: 0px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 270px;
  max-height: 20vh;
  overflow-y: auto;

}

.roadBlocksRow {
  background-color: #f6f7f899;
  border-left: 5px solid #f6f7f899;
  border-radius: 2px;
  list-style-type: none;
  padding: 0px;
  margin-bottom: 12px;
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  align-items: center;

  color: #7a7a7a;
  padding: 10px 18px;
  text-align: left;
  font-size: 14px;
  cursor: pointer;

}

.roadBlocksRow.active {
  background-color: #f6f7f8;
  /* Light grey background for the active marker */
  border-left: 5px solid #024761;
}


.roadBlockContent {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  flex-grow: 1;
  max-width: 140px;
  margin-right: 10px;
}


.icon {
  height: 18px !important;
  width: 18px !important;

}


.blueButton {
  background-color: #024761;
  border: 1px solid #024761;
  border-radius: 5px;
  color: white;
  padding: 2px 17px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 12px;
  cursor: pointer;
  line-height: 28px;
}

.whiteButton {
  background-color: #ffffff;
  border: 1px solid #024761;
  border-radius: 5px;
  color: #024761;
  padding: 2px 17px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 12px;
  cursor: pointer;
  line-height: 28px;
}