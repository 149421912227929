.container {
    background: #f5f6fa !important;
    padding: 20px !important;
    text-align: left;
}

.historicalFlowChart {
    flex-grow: 1;
    margin-bottom: 50px;
    height: 200px !important;
    display: block;
    width: 1021px;
}

reports-row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 40px;
    margin-top: 20px;
    gap: 1.5em;
}

export-button {
    display: inline-block;
    margin-left: 20px;
    border-style: solid;
    border-color: #e5e8eb;
    border-width: 1px;
    border-radius: 3px;
    background-color: #f6f7f8;
    padding: 4px 7px;
}

export-button a {
    display: flex;
    align-items: center;
    text-decoration: none;
    font-size: 12px;
}

timescale-buttons {

    display: flex;
    flex-direction: row;
    button {
        margin-right: 5px;

    }
}