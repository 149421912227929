@import url('https://fonts.googleapis.com/css2?family=Open+Sans&display=swap');

nav-row {
    display: flex;
    flex-direction: row;
    width: 100%;
}

button-row {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: end;
}

body{
    /* background: #f5f6fa;
    height: fit-content; */
 
}

.wrapper .sidebar{
    /* background: #024761;
    position: fixed;
    top: 0;
    left: 0;
    width: 225px;
    height: 100%;
    padding: 50px 0;
    transition: all 0.5s ease; */
}


.wrapper .sidebar ul li {
    display: block;
    padding: 15px 30px;
    border-bottom: 1px solid #024761;
    color: white;
    font-size: 16px;
    position: relative;
}

.wrapper .sidebar ul li .icon{
    color: white;
    width: 30px;
    display: inline-block;
}

 

.wrapper .sidebar ul li:hover,
.wrapper .sidebar ul li.active
{
    color: white;
  
    background: #cccccc6b;
    border-right: 2px solid #024761;
}

.wrapper .sidebar ul li:hover .icon,
.wrapper .sidebar ul li.active .icon{
    color: white;
}

.wrapper .sidebar ul li:hover:before,
.wrapper .sidebar ul li.active:before{
    display: block;
}

.wrapper .section{
    width: calc(100% - 225px);
    margin-left: 225px;
    transition: all 0.5s ease;
}

.wrapper .section .top_navbar{
    background: white;
    height: 50px;
    /* display: flex; */
    justify-content: space-between;
    padding: 4px 20px;
    position: sticky;
    top: 0;
    z-index: 100;
 
} 

 .wrapper .section .container{
    margin: 30px;
    background: #fff;
    padding: 30px;
    line-height: 28px;
}


body.active .wrapper .sidebar {
    left: -225px !important;
}

    
.sidebarActive{
    /* width: 0!important; */
}

body.active .wrapper .section{
    /* margin-left: 0;
    width: 100%; */
}

/* Sidebar styles */
.sidebar {
    /* background: #024761;
    position: fixed;
    top: 0;
    left: 0;
    width: 225px;
    height: 100%;
    padding: 50px 0;
    transition: all 0.5s ease;
    text-align: left; */
}

.sidebar ul {
    list-style: none;
    padding: 0;
}

.sidebar ul li {
    position: relative;
}

.sidebar ul li {
    display: block;
    padding: 200px 40px;
    border-bottom: 1px solid #024761;
    color: white;
    font-size: 16px;
    text-decoration: none;
}


/* Sidebar styles */
.sidebar {
    /* background: #024761;
    position: fixed;
    top: 0;
    left: 0;
    width: 225px;
    height: 100%;
    padding: 50px 0;
    transition: all 0.5s ease; */
}

.sidebar ul {
    list-style: none;

}



.sidebar ul li.hoverstyle:hover {
    /* background: #cccccc6b;
    border-right: 2px solid #024761;
    color: white;
    padding-bottom: 18px; */
}




.dropdown-container {
    display: none;
    background-color: #262626;
    padding-left: 8px;
  }


  .sidebar ul li {
    /* display: block;
    padding: 18px 30px;
    color: white;
    font-size: 16px;
    font-family: "Roboto", sans-serif;
    text-decoration: none;
    background: none;
    border: none; 
 
    background: none; */

}


 .dropdown-btn {
    display: block;
    padding: 12px 30px;
    color: white;
    font-size: 16px;
    font-family: "Roboto", sans-serif;
    text-decoration: none;
    background: none;
    border: none; 
    background: none;

}


.dropdown-btn {
 
    padding: 13px 50px;
    padding-left: 0;
}


.dropdown-btn .icon {
    margin-right: 20px; 
}

.icon {
    padding-right: 20px;
}

.dropdownContainer {
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.3s ease-out;

  }
  
  .show {
    max-height: 500px; 
    transition: max-height 0.5s ease-in;
  }


  .logo{

    margin-left: 30px;
    margin-right: 50px;
    margin-bottom: 20px;
  }


  .dropdownIcon {

    margin-left: 10px;
  }

  .dropdownItem {

   padding-left: 20px
  }

