.dropPinContainer {

  width: 280px;
  position: relative;
  padding: 15px;
  /* top: 0;
  left: 900px; */
  z-index: 999;
  background-color: white;
  box-shadow: -3px -3px 5px rgba(255, 255, 255, 0.1), 3px 3px 5px rgba(94, 104, 121, 0.2);
  border-radius: 2px;
}


.dropPinContainer::after {
 
  content:'';
  position: absolute;
  left: 0;
  right: 0;
  margin: 0 auto;
  width: 0;
  height: 0;
  border-top: 30px solid white;
  border-left: 40px solid transparent;
  border-right: 40px solid transparent;

}



text1area {

  width: 246px !important;
  font-size: 14px !important;
  margin-bottom: 0px !important;
}




 .input[type="text"] {
  border: 1px solid #7a7a7a4a;
  border-radius: 3px;
  padding: .5rem 1rem;
  font-size: 14px;
  font-family: "Roboto", sans-serif;
  color: #7a7a7a;
  margin-bottom: 10px;
}


.textarea {
  border: 1px solid #7a7a7a4a;
  border-radius: 3px;
  padding: .5rem 1rem;
  font-size: 14px;
  color: #7a7a7a;
  margin-bottom: 10px;
}


label {

  font-size: 14px !important;
}


.blueButton {
  background-color: #024761;
  border: 1px solid #024761;
  border-radius: 5px;
  color: white;
  padding: 2px 17px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 12px;
  cursor: pointer;
  line-height: 20px;
}

.whiteButton {
  background-color: #ffffff;
  border: 1px solid #024761;
  border-radius: 5px;
  color: #024761;
  padding: 2px 17px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 12px;
  cursor: pointer;
  line-height: 20px;
}

.iconsRow {

  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
  /* gap: 1.5em; */
  justify-content: space-between;


}

.bottomRow {
  display: flex;
  flex: 1;
  justify-content: space-between;
  align-items: center;
  margin-top: 15px;
}

.icon {
  height: 18px;
  width: 18px;

}




label {
  margin-right: 8px;
}


.dropDown .dropbtn {
  background-color: white;
  width: 280px;
  color: #7a7a7a;
  padding: 8px 8px;
  font-size: 14px;
  border-style: solid;
  border-color: #e5e8eb;
  border-width: 1px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.dropDown {
  position: relative;
  display: inline-block;
  text-align: left;
}



.dropdownContent {
  display: none;
  position: absolute;
  background-color: white;
  min-width: 280px;
  box-shadow: -3px -3px 5px rgba(255, 255, 255, 0.1), 3px 3px 5px rgba(94, 104, 121, 0.2);
  z-index: 1;
  font-size: 14px;
 
}

.dropdownContent a {
  color: #7a7a7a;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
  font-size: 14px;
}

.dropdownContent a:hover {
  background-color: #f6f7f8;
}

.dropDown:hover .dropdownContent {
  display: block;
}

.dropDown:hover .dropbtn {
  background-color: white;
}
