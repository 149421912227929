.container {
    background: white !important;
    padding: 30px !important;
    text-align: left;
    display: flex;
    flex-direction: column;
}


.email-icons-container img {
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 60%;
    height: 60%;
}
