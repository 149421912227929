.checkboxContainer {
    display: block;
    position: relative;
    padding-left: 25px;
    margin-left: 10px;
    margin-top: 0px;
    margin-bottom: 0px;
    cursor: pointer;
    user-select: none;
    border-radius: 2px;
    font-size: 14px;
}

.checkboxContainer input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    z-index: 2;
}

.checkboxCheckmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 14px;
    width: 14px;
    background-color: white;
    /* background: #f6f7f8; */
    border-radius: 2px;
    margin-top: 4px;
    border-style: solid;
    border-width: 1px;
    /* border-color: #e5e8eb; */
    border-color: #7a7a7a
}

.checkboxContainer input:checked ~ .checkboxCheckmark {
    background-color: #024761;
    border-color: #024761;
}

.checkboxCheckmark:after {
    content: "";
    position: absolute;
    display: none;
}

.checkboxContainer input:checked ~ .checkboxCheckmark:after {
    display: block;
}

.checkboxCheckmark:after {
    left: 4px;
    top: 1px;
    width: 3px;
    height: 6px;
    border: solid white;
    border-width: 0 3px 3px 0;
    transform: rotate(45deg);
}

label {
    font-family: "Roboto", sans-serif;
    font-size: 16px !important;
    color: #7a7a7a;
    margin-bottom: 5px;
}


.resetButton {
    background-color: #024761;
    border-radius: 5px;
    color: white;
    padding: 4px 12px;
    margin-left: 10px;
    margin-right: 10px;
    text-align: center;
    text-decoration: none;
    display: flex;
    font-size: 15px;
    cursor: pointer;
    line-height: 24px;
    align-items: center;
    justify-content: center;
  }
  
 