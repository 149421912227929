.circularSliderContainer {
    display: flex;
    align-items: center;
}

.circularSlider {
    margin-right: 10px;
}

.sliderWrapper {
    display: flex;
    align-items: center;
}

.sliderTrack {
    fill: none;
    stroke: #ccc;
    stroke-width: 7;
}

.sliderLine {
    stroke: #024761;
    stroke-width: 10;
}

.sliderHandle {
    fill: #024761;
    stroke-width: 30;
}

.cardinalText {
    font-size: 25px;
    fill: #7a7a7a;
}

.angleDisplay {
    text-align: center;
    font-size: 14px;
    margin-bottom: 2px;
}
