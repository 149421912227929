.map {
  height: 500px;
  width: 100%;
}


.zoomControl {
  background-color: white;
  border-radius: 4px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  padding: 5px;
  font-size: 14px;
  font-weight: bold;
  /* margin-top: 800px; */
}

.mapRangeContainer {
  z-index: 2000;
  position: absolute;
  background-color: white;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  top: 12px;
  left: 8px;
  padding: 8px;
  width: 190px;
}

.textareaTip {
  font-size: 13px;
  margin-top: -24px;
  margin-bottom: 20px;
}

.angleSliderLabel {
  display: block;
  width: 100%;
  text-align: center;
}

.angleSlider {
  -webkit-appearance: none;
  appearance: none;
  width: 100%;
  height: 10px;
  background: #f6f7f8;
  outline: none;
  -webkit-transition: .2s;
  transition: opacity .2s;
  font-size: 1px;
}

.angleSlider:hover {
  opacity: 1;
}

.angleSlider::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 16px;
  height: 16px;
  border-radius: 3px;
  background: #024761;
  cursor: pointer;
}

.angleSlider::-moz-range-thumb {
  width: 16px;
  height: 16px;
  background: #024761;
  cursor: pointer;
}

.modalOverlay {
  position: fixed;
  top: 0px;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.3);
  box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
  backdrop-filter: blur(2px);
  -webkit-backdrop-filter: blur(2px);
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 100;


}

.modalContent {
  width: 60%;
  position: fixed;
  top: 60px;
  left: 50%;
  transform: translate(-50%, -0%);
  background-color: white;
  padding: 20px;
  border-radius: 5px;
  box-shadow: -3px -3px 5px rgba(255, 255, 255, 0.1), 3px 3px 5px rgba(94, 104, 121, 0.2);
  max-height: 80%;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;

}

.closeIcon {
  margin-left: 250px;
}



.droppableArea {
  border: 2px dashed #7a7a7a;
  padding: 10px;
  min-height: 400px;

}

/* .droppedItem {
      background: #f0f0f0;
      padding: 5px;
      margin: 5px 0;
      border: 1px solid #ccc;
      border-radius: 4px;
  } */



.droppedItem {
  background-color: #f6f7f899;
  border: none;
  border-radius: 2px;
  color: #7a7a7a;
  padding: 10px 22px;
  margin-top: 10px;
  margin-bottom: 10px;
  text-align: left;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-between;
}



.draggedItem {
  background-color: #f6f7f899;
  border: none;
  border-radius: 2px;
  color: #7a7a7a;
  padding: 10px 22px;
  margin-top: 10px;
  margin-bottom: 10px;
  text-align: left;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  cursor: pointer;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}


.noScroll {
  overflow: hidden;
}

.ivsCard {
  margin-top: 50px;
  border-radius: 10px;
  padding-left: 18px;
  padding-bottom: 18px;
  padding-right: 18px;
  background-color: #024761;
  min-height: 200px;
  width: 100%;
}


.ivsImage {
  position: relative;
  width: 100%;
}

.titleDisplay {
  font-size: 24px;
  color: white;
  margin-top: 50px;
}

.messageDisplay {
  margin-top: 9px;
  color: white;
  font-size: 20px;
}

.imageCircle {
  position: absolute;
  aspect-ratio: 1 / 1;
  width: 18%;
  border-radius: 100%;
  margin-top: -9%;
  background: #024761;
}


.listItem {
  display: inline-flex;
  align-items: center;
  margin-right: 8px;
  padding: 0px 8px;
  background-color: #f6f7f899;
  border-radius: 2px;
}

.matrix {
  display: grid;
  background-color: white;
  /* aspect-ratio: 1 / 1; */
  max-height: 2000px;
  border: 1px solid #e5e8eb;
  background-color: #e5e8eb;
  gap: 2px;
}

.matrix>* {
  background-color: white;
  padding: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin: 0;
}

.matrixHeading {
  /* font-weight: bold; */
  font-size: 15px;
}

.empty {
  background-color: #f6f7f8;
}