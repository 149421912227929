.container {
  background: #f5f6fa !important;
  padding: 20px !important;
  text-align: left;
}


.tabs {
  /* display: flex;
  flex-direction: column; */
}



.tab {
  text-transform: none !important;
  background: white !important;
  color: #7a7a7a !important;
  font-size: 16px !important;
  font-weight: 400 !important;
  padding: 10px 0px !important;


  /* border-color: #e5e8eb !important;
  border-style: solid !important; */
  /* border-width: 0px !important; */
}

.tabButton {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
  justify-content: space-between;
  background-color: white;
  align-items:center;


}

.tabLabel {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap; 
  gap: .5em;
  align-items: center;



}

.blueCircle {
  display: inline-block;
  margin-top: 0px;
  width: 20px; 
  height: 20px; 
  border-radius: 50%;
  background-color: #024761; 
  color: white;
  text-align: center;
  line-height: 20px;
  font-size: 14px; 
}

.tablinks {

  /* padding-bottom: 16px;
  text-decoration: none;
  text-align: left;
  border: none;
  cursor: pointer; */
}



.tabcontent {
  display: none;

}


.tabcontent.show {
  display: block;
}
.email-checkbox-container {
  display: block;
  position: relative;
  padding-left: 5px;
  margin-top: 0px;
  margin-bottom: 0px;
  margin-right: 0px;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  border-radius: 2px;
  width: 20px;
  height: 20px;
}

.email-checkbox-container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

.checkbox-checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 18px;
  width: 18px;
  background: #f6f7f8;
  border-radius: 2px;
  margin-top: 1px;
  border-style: solid;
  border-width: 1px;
  border-color: #7a7a7a;
}

.email-checkbox-container input:checked + .checkbox-checkmark {
  background-color: #024761;
  border-color:  #024761;
}

.checkbox-checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

.email-checkbox-container input:checked + .checkbox-checkmark:after {
  display: block;
}

.email-checkbox-container .checkbox-checkmark:after {
  left: 5px;
  top: 1px;
  width: 4px;
  height: 8px;
  border: solid white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}


icons-row {
  /* display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
  gap: .5em;
  align-items: center; */


}




tbody tr:nth-child(even) {
  background-color: white; 
}

tbody tr:nth-child(odd) {
  background-color: #f6f7f880;


}



th, td {
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 10px;
  padding-right: 10px;
}




.number {
  /* font-size: 14px;  */
}

email-button-row {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
  gap: .9em; 
  margin-bottom: 15px;
  margin-top: 20px


}



.material-symbols-outlined.compose-icon {
  margin-right: 5px; /* Adjust as needed */
  color: white;
  line-height: 28px;
}


/* table widths */

.icons {

  width: 10%;
}

.from {
  width: 20%;
}

.message {
  width: 55%; 
}

.date {
  width: 15%; 
}


profile-container {
  border-style: solid;
  border-color: #e5e8eb;
  border-width: 1px;
  text-align: center;
  display: flex;
  flex-direction: column;
  flex-basis: 100%;
  padding: 10px;
  margin-bottom: 15px;



}


.pfp-image {
  border-radius: 10%;
  width: 20%;
}


/* support buttons */

.priorityButton {
  border: none;
  color: white;
  padding: 3px 12px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 13px;
  border-radius: 4px;
  border-style: solid;
  border-width: 1px;
  width: 120px;
}


.priorityButton.urgent {
  background-color: #f7c7c9; 
  color: #d13338;
  border-color: #d13338;
}

.priorityButton.important {
  background-color: #f9e4ae;; 
  color: #f28a00;
  border-color: #f28a00;
}

.priorityButton.request {
  background-color: #aad3e3; 
  color: #024761;
  border-color: #024761;
}

.priorityButton.nice {
  background-color: #d2e6bd; 
  color: #4a7529;
  border-color: #4a7529;
}


popup-row {
  display: flex;
  flex: 1;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

/* textarea {

  width: 500px;
}

settings-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

/* TABS */

.tabs {
  justify-content: center; 
}

 .tab {
  /* text-transform: none !important;
  background: #f6f7f8 !important;
  color: #7a7a7abf !important;
  font-size: 16px !important;
  font-weight: 400 !important;
  padding: 10px 12px !important;
  border-color: #e5e8eb !important;
  border-style: solid !important;
  border-width: 1px !important; */
}

 .activeTab {
  /* margin: 0px !important;
  cursor: disabled !important;
  background: white !important; */
  /* background: red !important; */
  /* color: #7a7a7a !important; */
}
