.matrix {
    display: grid;
    background-color: white;
    aspect-ratio: 1 / 1;
    height: 500px;
    border: 2px solid gray;
    background-color: gray;
    gap: 2px;
    overflow: auto;
}

.matrix>*,
.matrixRow>* {
    background-color: white;
    padding: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    margin: 0;
}

.matrixHeading {
    font-weight: bold;
}

.empty {
    background-color: lightgray !important;
}
