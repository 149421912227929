.simulationSpeedListContainer {
  width: 270px;
  padding: 15px;
}

.simulationSpeedLabel {
  font-size: 14px;
}

.simulationSpeedSlider {
  width: 88%;
  /* margin: 20px; */
}


.slidecontainer {
  /* width: 100%;
    margin-bottom: 20px;
    flex-direction: row;
    display: flex; */
}



.simulationSpeedSlider {
  margin-top: 20px;
  -webkit-appearance: none;
  appearance: none;
  width: 100%;
  height: 10px;
  background: #f6f7f8;
  outline: none;
  -webkit-transition: .2s;
  transition: opacity .2s;
  font-size: 1px;
}

.simulationSpeedSlider:hover {
  opacity: 1;
}

.simulationSpeedSlider::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 16px;
  height: 16px;
  border-radius: 3px;
  background: #024761;
  cursor: pointer;
}

.simulationSpeedSlider::-moz-range-thumb {
  width: 16px;
  height: 16px;
  background: #024761;
  cursor: pointer;
}


/* Checkbox */

.checkboxContainer {
  display: block;
  position: relative;
  padding-left: 25px;
  margin-top: 0px;
  margin-bottom: 15px;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  border-radius: 2px;
  font-size: 14px;
}

/* Hide the browser's default checkbox */
.checkboxContainer input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.checkboxCheckmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 14px;
  width: 14px;
  background: #f6f7f8;
  border-radius: 2px;
  margin-top: 4px;
  border-style: solid;
  border-width: 1px;
  border-color: #e5e8eb;
}

/* When the checkbox is checked, add a blue background */
.checkboxContainer input:checked~.checkboxCheckmark {
  background-color: #024761;
  border-color: #024761;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkboxCheckmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.checkboxContainer input:checked~.checkboxCheckmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.checkboxContainer .checkboxCheckmark:after {
  left: 4px;
  top: 1px;
  width: 3px;
  height: 6px;
  border: solid white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

.disabledPanel {
  opacity: 0.5;
  pointer-events: none;
}