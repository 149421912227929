.addRoutePanelContainer {
    /* display: flex;
    align-items: center; */
    background-color: white;
    border: none;
    top: 0;
    right: 0;
    position: fixed;
    margin-top: 75px;
    margin-right: 260px;
    padding: 10px;
    border-radius: 2px;
    box-shadow: -3px -3px 5px rgba(255, 255, 255, 0.1), 3px 3px 5px rgba(94, 104, 121, 0.2);
    line-height: 22px;
    width: 138px;
    cursor: pointer;
    font-size: 16px !important;
}


.addRouteButton {
    background-color: white;
    border: none;
    font-family: "Roboto", sans-serif;
    font-size: 16px !important;
    color: #7a7a7a;
    letter-spacing: .2px;
    display: flex;
    align-items: center;
    cursor: pointer;
    transition: none !important;
}



.pinDropIcon {
    fill: #024761 !important;
    height: 28px !important;
    width: 28px !important;
    margin-right: 5px !important;

}

.disabledPanel {
    opacity: 0.5;
    /* pointer-events: none; */
}

.tooltip {
    position: relative;
    display: inline-block;
}

.tooltip:hover::after {
    content: attr(data-tooltip);
    position: absolute;
    top: 100%;
    left: 50%;
    transform: translateX(-50%);
    padding: 8px;
    border-radius: 4px;
    background-color: black;
    color: white;
    white-space: nowrap;
    z-index: 1000;
    visibility: visible;
    opacity: 1;
}

.disabledPanel {
    pointer-events: none;
    /* Disable events if needed */
}

.tooltipWrapper {
    position: relative;
    display: inline-block;
}

.tooltipWrapper:hover::after {
    content: attr(data-tooltip);
    position: absolute;
    top: 100%;
    left: 50%;
    transform: translateX(-50%);
    padding: 8px;
    border-radius: 4px;
    background-color: black;
    color: white;
    white-space: nowrap;
    z-index: 1000;
    visibility: visible;
    opacity: 1;
}

.disabledIcon {
    color: grey;
    /* Adjust the color as needed */
}

.markerbutton {
    background-color: white;
    border: none;
    font-family: "Roboto", sans-serif;
    font-size: 16px !important;
    color: #7a7a7a;
    letter-spacing: .2px;
    display: flex;
    align-items: center;

}

.buttonFont {

    font-size: 16px;
}