.map {
  height: 100%;
  z-index: 10;

}

.liveContainer {
  height: calc(100vh - 52px);

}

.scenariosListContainer {
  background-color: white;
  padding: 15px;
}

.scenarioRow {
  background-color: #f6f7f899;

  border-left: 5px solid #f6f7f899;
  border-radius: 2px;
  list-style-type: none;
  padding: 0px;
  margin-bottom: 12px;
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  /* border: none; */
  color: #7a7a7a;
  padding: 10px 18px;
  text-align: left;
  font-size: 14px;
  cursor: pointer;
}

.scenariosContainer {
  list-style-type: none;
  padding: 0px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 270px;
  max-height: 500px;
  overflow-y: auto;
}


.modalOverlay {
  position: fixed;
  top: 0px;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.3);
  box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
  backdrop-filter: blur(2px);
  -webkit-backdrop-filter: blur(2px);
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 100;


}

.modalContent {

  position: absolute;
  top: 40%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  padding: 20px;
  border-radius: 5px;
  box-shadow: -3px -3px 5px rgba(255, 255, 255, 0.1), 3px 3px 5px rgba(94, 104, 121, 0.2);

}

.closeIcon {
  margin-left: 250px;
}





.liveText {
  color: #024761;
  font-weight: bold;
  margin-left: 10px;
}