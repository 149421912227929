.modalOverlay {
    position: fixed;
    top: 0px;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(255, 255, 255, 0.3);
    box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
    backdrop-filter: blur(2px);
    -webkit-backdrop-filter: blur(2px);
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 100;


}

.modalContent {
    width: 60%;
    position: fixed;
    top: 60px;
    left: 50%;
    transform: translate(-50%, -0%);
    background-color: white;
    padding: 20px;
    border-radius: 5px;
    box-shadow: -3px -3px 5px rgba(255, 255, 255, 0.1), 3px 3px 5px rgba(94, 104, 121, 0.2);

}

.closeIcon {
    margin-left: 250px;
}



.createColumn1 {

    display: flex;
    flex-direction: column;
    flex-basis: 100%;
}


.createColumn2 {

    display: flex;
    flex-direction: column;
    flex-basis: 100%;
    flex-wrap: wrap;
    justify-content: center;
}

double-column {
    /* display: flex;
    flex-direction: column;
    flex-basis: 100%; */
}

half-column {
    /* display: flex;
    flex-direction: column;
    flex-basis: 100%; */
}

quarter-column {
    /* display: flex;
    flex-direction: column; */
    /* flex-basis: 100%; */

}



@media screen and (min-width: 800px) {
    .createColumn1 {
        flex: .3;
    }


    .createColumn2 {
        flex: .7;
    }

    double-column {
        flex: 2;

    }

    half-column {
        flex: .5;
    }

    quarter-column {
        flex: .25;
    }


}


.createButton {
    background-color: #f6f7f8;
    border: none;
    border-radius: 2px;
    color: #7a7a7a;
    padding: 10px 10px;
    margin-top: 10px;
    text-align: left;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    cursor: pointer;
    width: 100%;
    display: flex;
    align-items: center;
    /* justify-content: space-between; */
}


.createName {
    background-color: #f6f7f8;
    border: none;
    border-radius: 2px;
    color: #7a7a7a;
    padding: 12px 10px;
    margin-top: 10px;
    margin-bottom: 10px;
    text-align: left;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    cursor: pointer;
    display: flex;
    align-items: center;

}


.createAdd {
    background-color: #f6f7f8;
    border: none;
    border-radius: 2px;
    color: #7a7a7a;

    padding: 12px 22px;
    margin-top: 10px;
    margin-bottom: 10px;
    text-align: left;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: space-between;

}


.createRow {
    background-color: #f6f7f8;
    display: flex;
    flex-direction: row;
    gap: 1.5em;
    padding-left: 10px;
    padding-right: 22px;
    height: 52px;
    align-items: center;
    align-content: center;
    justify-content: space-between;


}


.andButton {
    margin-top: 5px;
    margin-bottom: 5px;
    background-color: #024761;
    font-family: "Roboto", sans-serif;
    letter-spacing: .2px;
    color: #fff;
    padding: 8px 16px;
    border: none;
    border-radius: 3px;
    cursor: pointer;
    outline: none;
}


.andButton:disabled {
    background-color: #f6f7f8;
    color: #7a7a7a;
    cursor: auto;
}


.orButton {
    margin-top: 5px;
    margin-bottom: 5px;
    margin-left: 5px;
    background-color: #024761;
    font-family: "Roboto", sans-serif;
    letter-spacing: .2px;
    color: #fff;
    padding: 8px 16px;
    border: none;
    border-radius: 3px;
    cursor: pointer;
    outline: none;
}



.orDivider {
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 20px 0;
}

.orDivider span {
    margin-right: 10px;
}

.dashedLine {
    width: 100%;
    border: none;
    border-top: 1px dashed #000;
}

.or {
    margin: 16px 0;
    overflow: hidden;

    &::after {
        content: "";
        width: 100%;
        background-color: #7a7a7a;
        display: inline-block;
        height: 1px;
        position: relative;
        vertical-align: middle;
        width: 100%;
        margin-right: -50%;
        left: 8px;
    }
}
