.tableRow{

    height: 350px;
    overflow-y: auto;

}

.emailCheckboxContainer {
    display: block;
    position: relative;
    padding-left: 5px;
    margin-top: 0px;
    margin-bottom: 0px;
    margin-right: 0px;
    cursor: pointer;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    border-radius: 2px;
    width: 20px;
    height: 20px;
  }
  
  .emailCheckboxContainer input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }
  
  .checkboxCheckmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 18px;
    width: 18px;
    background: #f6f7f8;
    border-radius: 2px;
    margin-top: 1px;
    border-style: solid;
    border-width: 1px;
    border-color: #7a7a7a;
  }
  
  .emailCheckboxContainer input:checked + .checkboxCheckmark {
    background-color: #024761;
    border-color:  #024761;
  }
  
  .checkboxCheckmark:after {
    content: "";
    position: absolute;
    display: none;
  }
  
  .emailCheckboxContainer input:checked + .checkboxCheckmark:after {
    display: block;
  }
  
  .emailCheckboxContainer .checkboxCheckmark:after {
    left: 5px;
    top: 1px;
    width: 4px;
    height: 8px;
    border: solid white;
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
  }



  .iconsRow {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
    gap: .5em;
    align-items: center;
  
  
  }

/* TABLE WIDTH */

  .icons {

    width: 10%;
  }
  
  .from {
    width: 20%;
  }
  
  .message {
    width: 55%; 
  }
  
  .date {
    width: 15%; 
  }
  