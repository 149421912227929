/* body::before {
    content: "";
    background: linear-gradient(90deg, #024761a8, #f26627a8), url("/public/trucks.jpg");
    background-size: cover;
    background-position: center center; 
    background-repeat: no-repeat;
    position: fixed;
    top: 0px;
    right: 0px;
    bottom: 0px;
    left: 0px;
    opacity: 0.7;

  } */

  .loginBackground {
    content: "";
    background: linear-gradient(90deg, #024761a8, #f26627a8), url("/public/trucks.jpg");
    background-size: cover;
    background-position: center center; 
    background-repeat: no-repeat;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    opacity: 0.7;
    z-index: -1; /* Ensures the background is behind the content */
}
  
  log-in-container {
  

    position: fixed;
    width: 80%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: white;
    padding: 20px;
    border-radius: 5px;
    box-shadow: -3px -3px 5px rgba(255, 255, 255, 0.1), 3px 3px 5px rgba(94, 104, 121, 0.2);
  }
  
  
  @media screen and (min-width: 800px) {
    log-in-container {
      width: 700px; 
  
    }
  }
  
  .titlePadding {
    padding-bottom: 0px;
  }

  @media screen and (min-width: 800px) {
    .titlePadding {
      padding-bottom: 30px;
  
    }
  }
  
  
  .center {
  
    width: 30%;
    margin-bottom: 20px;
  }
  
  sign-in {
    line-height: 1.4;
  }
  
  .forgot {
    margin-top: 0px;
    font-size: 14px;
    line-height: 28px;
    text-align: right;
  
  }





.signinCheckboxContainer {
  display: block;
  position: relative;
  padding-left: 25px;
  margin-top: 0px;
  font-size: 14px !important;
  line-height: 28px !important;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  border-radius: 2px;
}

/* Hide the browser's default checkbox */
.signinCheckboxContainer input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.signinCheckboxCheckmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 14px;
  width: 14px;
  background: #f6f7f8;
  border-radius: 2px;
  margin-top: 6px;
  margin-right: 0px;
  border-style: solid;
  border-width: 1px;
  border-color: #e5e8eb;
}



/* When the checkbox is checked, add a blue background */
.signinCheckboxContainer input:checked~.signinCheckboxCheckmark {
  background-color: #024761;
  border-color: #024761;
}

/* Create the checkmark/indicator (hidden when not checked) */
.signinCheckboxCheckmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.signinCheckboxContainer input:checked~.signinCheckboxCheckmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.signinCheckboxContainer .signinCheckboxCheckmark:after {
  left: 4px;
  top: 1px;
  width: 3px;
  height: 6px;
  border: solid white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}