.brockContainer {
  padding: 20px;
  background: #d2e6bdcc;
  border-left-style: solid;
  border-color: #4a7529;
  border-left-width: 10px;

}

.brockRow {
  display: flex;
  align-items: center;
  gap: 1.5em;

}

.brockIcon {
  height: 21px;
  width: 21px;
}

.brockIcon svg {
  color: #4a7529;
  height: 21px;
  width: 21px;
  align-self: center;

}

.doverflowChart {
  flex-grow: 1;
  margin-bottom: 50px;
  height: 200px !important;
  display: block;
  width: 100% !important;
}

.leshuttleflowChart {
  flex-grow: 1;
  margin-bottom: 50px;
  height: 200px !important;
  display: block;
  width: 100% !important;
}

.loadChart {
  width: 100%;
  max-width: 600px;
}

#leshuttleflowChart {
  flex-grow: 1;
  height: 200px !important;
}

#flowChart {
  flex-grow: 1;
  margin-top: 50px;
  height: 200px !important;
  margin-bottom: 100px;
}

#historicalChart {
  flex-grow: 1;
  height: 400px !important;
}

.muaChart {
  flex-grow: 1;
  /* height: 100px !important; */
  width: 100%;
  aspect-ratio: 100/100;
}


.notificationRow {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
  margin-bottom: 20px;

}

.warningIcon svg {
  color: #e31837;
}

.messageIcon svg {
  color: #024761;
}

.supportIcon svg {
  color: #4a7529;
}


.material-symbols-outlined {
  font-variation-settings:
    'FILL' 0,
    'wght' 200,
    'GRAD' 0,
    'opsz' 1
}

.greenText {

  color: #4a7529;


}

.doubleColumn {
  display: flex;
  flex-direction: column;
  flex-basis: 100%;

}


.row {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
  gap: 1.5em;


}

.column {
  display: flex;
  flex-direction: column;
  flex-basis: 100%;


}

.halfColumn {
  display: flex;
  flex-direction: column;
  flex-basis: 100%;



}


@media screen and (min-width: 800px) {
  .column {
    flex: 1;


  }

  .doubleColumn {
    flex: 2;

  }

  .halfColumn {
    flex: .5;
  }

  /* third-colum {
      flex: .25;
    } */


}

.warningIcon {
  color: red;
}

.messageIcon {
  color: #024761;
}