.container {
  background: #f5f6fa !important;
  padding: 20px !important;
  text-align: left;
}




.statusButton {
  border: none;
  color: white;
  padding: 3px 12px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 13px;
  border-radius: 4px;
  border-style: solid;
  border-width: 1px;
  width: 100px;

}

.statusButton.sent {
  background-color: #d2e6bd;
  color: #4a7529;
  border-color: #4a7529;


}

.statusButton.scheduled {
  background-color: #f9e4ae;
  ;
  color: #f28a00;
  border-color: #f28a00;

}

.statusButton.pending {
  background-color: #aad3e3;
  color: #024761;
  border-color: #024761;

}

.actionIcon {
  cursor: pointer;
  transition: color 0.3s ease;
}

.editIcon:hover {
  color: #4a90e2;
  /* A blue shade for edit */
}

.deleteIcon:hover {
  color: #e74c3c;
  /* A red shade for delete */
}