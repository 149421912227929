
.container {
    background: #f5f6fa;
    padding: 30px;
    text-align: left;
    display: flex;
    flex-direction: column;
}

.tabs {
    justify-content: center;
}

.tab {
    text-transform: none !important;
    background: #f6f7f8 !important;
    color: #7a7a7abf !important;
    font-size: 16px !important;
    font-weight: 400 !important;
    padding: 10px 12px !important;
    border-color: #e5e8eb !important;
    border-style: solid !important;
    border-width: 1px !important;
    flex: 1;
}

.activeTab {
    margin: 0px !important;
    cursor: disabled !important;
    background: white !important;
    /* background: red !important; */
    color: #7a7a7a !important;
}

.simpleTabpanel {
    height: 500px;
    overflow: auto;
}

.gridBlock {
    display: grid;
    width: 100%;
    gap: 1px;
    background-color: #e5e8eb;
    border: 1px solid #e5e8eb;
    margin-bottom: 8px;
    position: relative;

    &:hover {
        border: 1px solid gray;
        background-color: gray;
    }
}

.blockChild {
    padding: 8px;
    background-color: white;
    position: relative;
}

.contextMenu {
    flex-direction: column;
    background-color: white;
    user-select: none;
    position: absolute;
    padding: 16px;
    translate: -110% -50%;
    box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);

    div {
        padding: 0 8px;
        display: flex;
        align-items: center;

        &:hover {
            background-color: #f5f6fa;
        }

        svg {
            margin-right: 2px;
        }
    }
}

.contentDivider, .contentSpacer {
    width: 100%;
}

.contentSpacer {
    border: none;
}

.contentList {
    margin: 0;
}
.contentList input[type="text"] {
    margin: 0;
    padding: 4px;
    width: calc(100% - 30px);
}

/* type text only to increase specificity */
.contentTitle[type="text"] {
    padding: 4px;
    width: calc(100% - 8px);
    margin: 0;
}

.contentParagraph {
    resize: none;
    padding: 4px;
    margin: 0;
    width: calc(100% - 8px);
}

.contentImage {
    object-fit: contain;
    width: 100%;
    pointer-events: none;
}

.contentVideo {
    min-width: 100%;
    min-height: 100%;
}
