.map {
  height: 100%;
  width: 100%;


}


.zoomControl {
  background-color: white;
  border-radius: 4px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  padding: 5px;
  font-size: 14px;
  font-weight: bold;
  margin-top: 100px;
}

.tasContainer {
  height: calc(100vh - 52px);
  width: 100%;


}

.wrapper .section .container {
  background: #f5f6fa;
  padding: 0px !important;
  line-height: 28px;
  font-size: 20px;
}


#portalFlowChart {
  flex-grow: 1;
  z-index: 20000;
 
}

.accidentsContainer {
  top:400px;
  width:500px;
  height: 200px !important;
  z-index: 9999999999;
  position: absolute;
}

.simulationSpeedContainer {
  top: 700px;
  width: 500px;
  height: 200px !important;
  z-index: 9999999999;
  position: absolute;
  background-color: white;
}

.simulationSpeedPanel{
  /* top: 700px; */
}




.leaflet-div-icon.custom-leaflet-icon {

  background-color: white; 
}

.leaflet-div-icon.custom-leaflet-icon img {

  filter: invert(100%) sepia(0%) saturate(0%) hue-rotate(0deg);
}


.tabs {
  justify-content: center; 
}

 .tab {
  text-transform: none !important;
  background: #f6f7f8 !important;
  color: #7a7a7abf !important;
  font-size: 16px !important;
  font-weight: 400 !important;
  padding: 10px 12px !important;
  border-color: #e5e8eb !important;
  border-style: solid !important;
  border-width: 1px !important;
}

 .activeTab {
  margin: 0px !important;
  cursor: disabled !important;
  background: white !important;
  /* background: red !important; */
  color: #7a7a7a !important;
}





