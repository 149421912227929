.modalOverlay {
    position: fixed;
    top: 0px;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(255, 255, 255, 0.3);
    box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
    backdrop-filter: blur(2px);
    -webkit-backdrop-filter: blur(2px);
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 100;
  
  
  }
  
  .modalContent {
  
    position: absolute;
    top: 40%; 
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: white;
    padding: 20px;
    border-radius: 5px;
    box-shadow: -3px -3px 5px rgba(255, 255, 255, 0.1), 3px 3px 5px rgba(94, 104, 121, 0.2);
  
  }
  
  .closeIcon {
    margin-left: 250px;
  }