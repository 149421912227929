.topNavbar {
    background: white;
    height: 42px;
    justify-content: space-between;
    padding: 5px 40px;
    position: sticky;
    top: 0;
    z-index: 100 ;
}

.navRow {
	display: flex;
	flex-direction: row;
	width: 100%;
}

.menuIcon svg {
    font-size: 42px;
    color: #024761;
}


.iconBadgeContainer {
    margin-right: 25px;
    position: relative;
}

   
.iconBadge {
    background-color: #e31837;
    font-size: 12px;
    line-height: 20px;
    color: white;
    text-align: center;
    width: 20px;
    height: 20px;
    border-radius: 35%;
    position: relative;
    top: 0px;
    left: 17px;
    padding-top: 0px;
}

.notificationsIcon {
    font-size:38px !important;
    margin-top:2px;
    position: absolute;
    align-self: center !important;
}




