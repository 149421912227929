
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@600&family=Roboto:wght@300;400;500&display=swap');

label {
    font-family: "Roboto", sans-serif;
    font-size: 16px;
    color: #7a7a7a;
}

form {

    width: 100%;
}






.dropArea {
    /* width: 500px; */
    height: 300px;
    /* padding: 30px; */
    background: white;
    text-align: center;
    border-radius: 20px;
    margin-bottom: 30px;
    margin-top: 10px;

}

.imgView {

    width: 100%;
    height: 100%;
    border-radius: 20px;
    border: 2px dashed #7a7a7a;
    background-position: center;
    background-size: cover;

}

.imgView img {
    width: 100px;
    margin-top: 25px;
}

.imgView span {
    display: block;
    font-size: 12px;
    margin-top: 25px;
}

.uploadImage {
    margin-bottom: 40px;
}



sticky-column {
    display: flex;
    flex-direction: column;
    flex-basis: 100%;
    border-radius: 20px;
    height: 100%;
    position: sticky;
    top: 80px;

}



half-column {
    display: flex;
    flex-direction: column;
    flex-basis: 100%;

}

@media screen and (min-width: 800px) {
    column {
        flex: 1
    }

    double-column {
        flex: 2
    }

    half-column {
        flex: .5
    }

    sticky-column {
        flex: 1;
        margin: 0 10px;
    }

}

.form-col-half {
    max-width: 100%;
    width: 100%;
    -webkit-box-flex: 0;
    -ms-flex: 0 0 100%;
    flex: 0 0 100%
}

.form-col-full {
    max-width: 100%;
    width: 100%;
    -webkit-box-flex: 0;
    -ms-flex: 0 0 100%;
    flex: 0 0 100%
}

preview {
    font-size: 20px;
    margin: 0 0 20px;
}

name-output {
    font-size: 20px;
    color: white;
    height: 100px;
}

address-output,
description-output {
    padding: 20px 10px 10px 10px;
    font-size: 18px;
}

date-output,
time-output {
    font-size: 18px;
    color: white;
}

.image-container {
    position: relative;
    width: 100%;
    height: 300px;
    /* Set the height to 300px */
}

#uploadButton {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

#uploadimage {
    width: 100%;
    height: 300px;
    /* Set the height to 300px */
    object-fit: cover;
    /* Ensure the image covers the container */
}

.facilitiesButton {
    padding: 8px 10px;
    margin: 5px 20px 10px 0px;
    font-family: "Roboto", sans-serif;
    font-size: 12px;
    background-color: white;
    border: 1px solid #024761;
    border-radius: 3px;
    color: #024761;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s;
}



dashboard-container {
    /* background: white !important;
    padding: 20px;
    flex: 1; */
}

notification-row {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
    margin-bottom: 20px;


}
